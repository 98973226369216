<template>
  <simpleNotifyPage :action="action">
    <template v-slot:title> 核准申請 </template>
    <template v-slot:subtitle> {{ storeName }}可以向你訂購了！ </template>
    <template v-slot:text-label> 合作狀態： </template>
    <template v-slot:text-value> 合作中 </template>
  </simpleNotifyPage> 
</template>

<script>
export default {
  components: {
    simpleNotifyPage: () => import("@/modules/base/views/notifyPage/simpleNotifyPage.vue")
  },
  computed: {
    storeName() {
      return this.$route.query.name;
    },
  },
  methods: {
    action() {
      this.$helper.closeLiff()
    },
  },
};
</script>
